<template>
  <v-btn
      class="mr-0"
      icon
      :color="color"
      :href="link"
      target="_blank"
  >
    <v-icon large>{{icon}}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SocialIcon",
  props: {
    color: {
    type: String,
    default: function () {
      return '#00778c';
     }
    },
    icon: {
      type: String,
      default: function () {
        return 'mdi-facebook';
      }
    },
    link: {
      type: String,
      default: function () {
        return '#'
      }
    }
  }
}
</script>

<style scoped>

</style>